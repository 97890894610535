import { ApolloClient, InMemoryCache } from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import { from, HttpLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from "@apollo/client/link/error";

import { setContext } from "@apollo/client/link/context";
import User from 'store/user';

const ErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) console.log('graphQLErrors', graphQLErrors);
  if (networkError) console.log('networkError', networkError);
});

const uploadLink = createUploadLink({ 
  uri: process.env.REACT_APP_API 
});

const AuthLink = setContext((_, { headers }) => {
  const token = User.get('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
});

const additiveLink = from([
  ErrorLink,
  AuthLink,
  uploadLink
]);

const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache({
  	addTypename: false
  })
});

export default client;