import { Menu } from "antd";
import {
  AppstoreOutlined,
  DollarOutlined,
  UserOutlined,
  WalletOutlined,
  FileDoneOutlined,
  AreaChartOutlined,
  ShoppingOutlined,
  ReadOutlined,
  CarryOutOutlined,
  TeamOutlined,
  ImportOutlined,
  ExportOutlined,
  CalendarOutlined,
  BuildOutlined,
  FileTextOutlined
} from "@ant-design/icons";

const MenuRoutes = ({ handleClick, keys}) => {
  return (
    <>
      <Menu
        onClick={handleClick}
        style={{ width: 280 }}
        defaultSelectedKeys={keys}
        selectedKeys={keys}
        openKeys={["expenses"]}
        mode="inline"
        expandIcon={<span></span>}
      >
        <Menu.Item icon={<AppstoreOutlined />} key={"/"}>
          Dashboard
        </Menu.Item>

        {/*
                 <Menu.Item icon={<CalendarOutlined />} key={'/schedules'}>
                     Agenda
                 </Menu.Item>
                 */}

        {/*
                <Menu.Item icon={<DollarOutlined />} key={'/sales'}>
                    Vendas
                </Menu.Item>
                */}

        <Menu.Item icon={<DollarOutlined />} key={"/cash-launch"}>
          Caixa
        </Menu.Item>
        <Menu.Item icon={<ExportOutlined />} key={"/bills/expenses"}>
          Contas a Pagar
        </Menu.Item>
        <Menu.Item icon={<ImportOutlined />} key={"/bills/income"}>
          Contas a Receber
        </Menu.Item>
        <Menu.Item icon={<TeamOutlined />} key={"/clients"}>
          Clientes
        </Menu.Item>
        <Menu.Item icon={<BuildOutlined />} key={"/suppliers"}>
          Fornecedores
        </Menu.Item>
        <Menu.Item icon={<ShoppingOutlined />} key={"/products"}>
          Meus Produtos
        </Menu.Item>

        <Menu.Item icon={<ReadOutlined />} key={"/stock"}>
          Controle de Estoque
        </Menu.Item>
        <Menu.Item icon={<WalletOutlined />} key={"/das"}>
          DAS
        </Menu.Item>
        <Menu.Item icon={<FileTextOutlined />} key={"/invoices"}>
          Notas Fiscais
        </Menu.Item>
        <Menu.Item icon={<FileDoneOutlined />} key={"/receipts"}>
          Recibos
        </Menu.Item>

        <Menu.Item icon={<AreaChartOutlined />} key={"/reports"}>
          Relatórios
        </Menu.Item>

        <Menu.Item icon={<CarryOutOutlined />} key={"/services"}>
          Serviços
        </Menu.Item>

        <Menu.Item icon={<UserOutlined />} key={"/profile"}>
          Meu Perfil
        </Menu.Item>
      </Menu>
    </>
  );
};

export default MenuRoutes;
