import { Menu } from "antd";
import {
	AppstoreOutlined,
	UserOutlined,
	CarryOutOutlined,
	DollarOutlined,
	TagOutlined,
	MessageOutlined,
	WalletOutlined,
	ImportOutlined,
	TeamOutlined,
	BlockOutlined,
	FileTextOutlined,
	IdcardOutlined,
} from "@ant-design/icons";

const MenuRoutes = ({ handleClick, keys, userInfo }) => {
	return (
		<>
			<Menu
				onClick={handleClick}
				style={{ width: 280 }}
				defaultSelectedKeys={keys}
				selectedKeys={keys}
				openKeys={["expenses"]}
				mode="inline"
				expandIcon={<span></span>}
			>
				<Menu.Item icon={<AppstoreOutlined />} key={"/"}>
					Dashboard
				</Menu.Item>

				<Menu.Item icon={<UserOutlined />} key={"/users"}>
					MEI
				</Menu.Item>

				<Menu.Item icon={<FileTextOutlined />} key={"/invoices"}>
					Notas Fiscais
				</Menu.Item>

				<Menu.Item icon={<DollarOutlined />} key={"/cash-launch"}>
					Caixa
				</Menu.Item>

				{/* <Menu.Item icon={<ImportOutlined />} key={"/bills/income"}>
          Contas a Receber
        </Menu.Item> */}

				<Menu.Item icon={<WalletOutlined />} key={"/das"}>
					DAS
				</Menu.Item>

				{!process?.env?.REACT_APP_WHITELABEL && (
					<Menu.Item icon={<DollarOutlined />} key={"/credits"}>
						Créditos
					</Menu.Item>
				)}

				<Menu.Item icon={<TagOutlined />} key={"/vouchers"}>
					Vouchers
				</Menu.Item>

				<Menu.Item icon={<TeamOutlined />} key={"/admins"}>
					Administradores
				</Menu.Item>

				{userInfo.type === 2 && (
					<>
						<Menu.Item icon={<IdcardOutlined />} key={"/companies"}>
							Empresas
						</Menu.Item>
					</>
				)}

				<Menu.Item icon={<BlockOutlined />} key={"/groups"}>
					Grupos
				</Menu.Item>

				<Menu.Item icon={<MessageOutlined />} key={"/tickets"}>
					Tickets Abertos
				</Menu.Item>

				<Menu.Item icon={<CarryOutOutlined />} key={"/services"}>
					Serviços
				</Menu.Item>
			</Menu>
		</>
	);
};

export default MenuRoutes;
