import React, { useEffect } from "react";
import Loading from "components/Loading/Page";
import Client from "functions/apollo";
import User from "store/user";
import jwt from "jsonwebtoken";

import { gql } from "@apollo/client";

function AppWrapper({children, client}) {

  const [loading, setLoading] = React.useState(true);


  useEffect(() => {
    try {
      let data = localStorage.getItem("user");
      if (data) {
        
        data = JSON.parse(data);

        client
          .query({
            query: gql`
              query AuthCheck($token: String!) {
                AuthCheck(token: $token) {
                  result
                  code
                }
              }
            `,
            variables: {
              token: data.token,
            },
            fetchPolicy: "no-cache",
          })
          .then(async (check) => {

            console.log("check", check);

            if(check.data.AuthCheck.result) {

              User.setData(data).then(() => {
                client
                .query({
                  query: gql`
                    query GetUserType {
                      GetUserType {
                        type
                        subtype
                      }
                    }
                  `,
                  fetchPolicy: "no-cache",
                })
                .then(({ data }) => {
                  console.log("data", data);
                  User.info = data.GetUserType;
                  setLoading(false);
                });
              });

            } else {
              await localStorage.removeItem("user");
              setLoading(false);
            }
          });

      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  }, []);

  if (loading) return <Loading />;
  return children;
}

export default AppWrapper;
