import { gql } from '@apollo/client';

export const LOGIN = gql`
query Login($input: Login!) {
  Login(input: $input) {
    name,
    token
  }
}
`;

export const USER_TYPE = gql`
  query GetUserType {
    GetUserType
  }
`;
