import React, {useState} from 'react';
import { Form, Icon, Input, Button, Checkbox } from "antd";
import useImperativeQuery from 'functions/hooks/useImperativeQuery';
import * as Queries from './Queries';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';
import { useApolloClient } from "@apollo/client";
import User from 'store/user';

function Login({history}) {

	const client = useApolloClient();
	const [state, setState] = useState({});
	const [loading, setLoading] = useState(false);
	const loginAction = useImperativeQuery(Queries.LOGIN);

	const login = async () => {

		try {

			if(!state.email || !isEmail(state.email)) return Swal.fire({
										title: "Erro",
										text: 'Por favor, insira um e-mail válido.',
										type: "error",
										confirmButtonText: "OK"
									});

			if(!state.password) return Swal.fire({
										title: "Erro",
										text: 'Por favor, insira uma senha válida.',
										type: "error",
										confirmButtonText: "OK"
									});
			setLoading(true);

			let input = {...state};

			const {REACT_APP_PARTNER_ID, REACT_APP_TYPE} = process.env;

			if(REACT_APP_PARTNER_ID && REACT_APP_PARTNER_ID !== '') {
				input.partner_id = REACT_APP_PARTNER_ID;
				input.type = REACT_APP_TYPE;
			}

			console.log(input);

			const {data, errors} = await loginAction({
				input
			});

			setLoading(false);

			if(!errors) {
				await User.setLogin(data.Login, client);
				history.replace("/");
			} else {
				const errorMessage = errors[0].message || 'Não foi possível fazer seu login no momento.';
				return Swal.fire({
					title: "Erro",
					text: errorMessage,
					type: "error",
					confirmButtonText: "OK"
				});
			}
		} catch(e) {
			setLoading(false);
			console.log(e);
			return Swal.fire({
				title: "Erro",
				text: 'Não foi possível fazer login no momento. Por favor, tent novamente mais tarde.',
				type: "error",
				confirmButtonText: "OK"
			});
		}

	}

	console.log(process.env.REACT_APP_THEME);

		let logo = require('assets/bases/openmei/logo.png').default;
		if(process.env.REACT_APP_THEME && process.env.REACT_APP_THEME !== '') {
			logo = require(`assets/bases/${process.env.REACT_APP_THEME}/logo.png`).default;
		}


	  return (
	   		<div className="login">
	   			<div className="login-wrapper">
	   				<div className="login-inset">
	   					<div className="logo-wrapper">
	   						<img
	   							src={logo}
	   							alt=""
	   						/>
	   					</div>

	   					<Form
	   						onSubmit={null}
	   						layout={"vertical"}
	   						className="login-form"
	   					>
	   						<Form.Item>
	   								<Input
	   									rules={[{ required: true }]}
	   									prefix={
	   										<Icon
	   											type="mail"
	   											style={{
	   												color: "rgba(0,0,0,.25)"
	   											}}
	   										/>
	   									}
	   									placeholder="E-mail"
	   									size="large"
	   									onChange={event => {
	   										const { value } = event.target;
	   										setState(state => {
	   											return {
	   												...state,
	   												email: value
	   											}
	   										});
	   									}}
	   								/>
	   							
	   						</Form.Item>
	   						<Form.Item>
	   							
	   								<Input
	   									rules={[{ required: true }]}
	   									prefix={
	   										<Icon
	   											type="lock"
	   											style={{
	   												color: "rgba(0,0,0,.25)"
	   											}}
	   										/>
	   									}
	   									type="password"
	   									placeholder="Senha"
	   									size="large"
	   									onChange={event => {
	   										const { value } = event.target;
	   										setState(state => {
	   											return {
	   												...state,
	   												password: value
	   											}
	   										});
	   									}}
	   								/>
	   								
	   						</Form.Item>

	   						<div className="options">
	   							<a className="login-form-forgot" href="reset-password">
	   								Esqueci minha senha
	   							</a>
	   							<Button
	   								size={"large"}
	   								loading={loading}
	   								onClick={login}
	   								type="primary"
	   								htmlType="submit"
	   								className="login-form-button"
	   							>
	   								Continuar
	   							</Button>
	   							
	   						</div>


	   						<div className="register">
								<span>Ainda não é cadastrado?</span>

								<Button
									size={"large"}
									onClick={() => {
										window.location.href = process?.env?.REACT_APP_URL_SIGNUP || 'https://www.openmei.com.br/fale-conosco?pacote=pacote-super';
									}}
									type="primary"
									htmlType="submit"
									className="login-form-button"
								>
									Cadastre-se
								</Button>
	   						</div>

	   						

	   						
	   					</Form>
	   				</div>
	   			</div>
	   		</div>
	  );
}

export default Login;
