import React, {lazy, Suspense} from "react";
import { Route, Switch } from "react-router-dom";
import AccessHandler from "../functions/accessHandler";
import Loading from "components/Loading/Page";

// Scenes Imports

const NotFound = lazy(() => import("./not-found"));

// Routes Config

const routes = [
  {
    path: "/",
    exact: true,
    component: lazy(() => import("./dashboard")),
  },
  {
    path: "/cash-launch",
    exact: true,
    component: lazy(() => import("./cash-launch")),
  },
  {
    path: "/clients",
    exact: true,
    component: lazy(() => import("./clients")),
  },
  {
    path: "/das",
    exact: true,
    component: lazy(() => import("./das")),
  },
  {
    path: "/stock",
    exact: true,
    component: lazy(() => import("./stock")),
  },
  {
    path: "/products",
    exact: true,
    component: lazy(() => import("./products")),
  },
  {
    path: "/services",
    exact: true,
    component: lazy(() => import("./services")),
  },
  {
    path: "/receipts",
    exact: true,
    component: lazy(() => import("./billing-receipts")),
  },
  {
    path: "/reports",
    exact: true,
    component: lazy(() => import("./reports")),
  },
  {
    path: "/profile",
    exact: true,
    component: lazy(() => import("./profile")),
  },
  {
    path: "/bills/income",
    exact: true,
    component: lazy(() => import("./bills/income")),
  },
  {
    path: "/bills/expenses",
    exact: true,
    component: lazy(() => import("./bills/expenses")),
  },
  {
    path: "/suppliers",
    exact: true,
    component: lazy(() => import("./suppliers")),
  },
  // {
  //   path: "/schedules",
  //   exact: true,
  //   component: Schedules
  // },
  {
    path: "/sales",
    exact: true,
    component: lazy(() => import("./schedules")),
  },
  {
    path: "/invoices",
    exact: true,
    component: lazy(() => import("./invoices")),
  },
  {
    path: "/credits",
    exact: true,
    component: lazy(() => import("./credits")),
  },
  {
    path: "/users",
    exact: true,
    component: lazy(() => import("./users/List")),
  },
  {
    path: "/create-user",
    exact: true,
    component: lazy(() => import("./users/New")),
  },
  {
    path: "/user/:id",
    exact: true,
    component: lazy(() => import("./users/User")),
  },
  {
    path: "/user/logs/:id",
    exact: true,
    component: lazy(() => import("./users/UserLogs")),
  },
  {
    path: "/vouchers",
    exact: true,
    component: lazy(() => import("./vouchers")),
  },
  {
    path: "/tickets",
    exact: true,
    component: lazy(() => import("./tickets")),
  },
  {
    path: "/admins",
    exact: true,
    component: lazy(() => import("./admins")),
  },
  {
    path: "/groups",
    exact: true,
    component: lazy(() => import("./groups")),
  },
  {
    path: "/companies",
    exact: true,
    component: lazy(() => import("./companies")),
  },
];

// Routes Functions

function RouteWithSubRoutes(route) {
  return (
      <Suspense fallback={<Loading/>}>
        <AccessHandler path={route.path}>
          <Route
            path={route.path}
            exact={route.exact ? true : false}
            render={(props) => <route.component {...props} routes={route.routes} />}
          />
        </AccessHandler>
      </Suspense>
  );
}

function RouteConfig() {
  let returnArray = [];
  routes.map((route, i) => {
    let routeObj = {
      ...route
    };
    returnArray.push(<RouteWithSubRoutes key={i} {...routeObj} />);
  });

  

  //return returnArray;

  return (
    <Switch>
        {returnArray}
        <Route component={NotFound}/>
    </Switch>
  )
}

export default RouteConfig;
