import React from 'react';
import { Layout } from 'antd';

import Routes from '../index';

import Sider from 'components/Sider';
import Header from 'components/Header';

const { Footer, Content } = Layout;

function Main(props) {
	return (
			<Layout className="main-wrapper">
		      		<Sider  history={props.history}/>
		      	  <Layout>
		      	    <Header {...props}/>
		      	    <Content>
		      	    	<Routes/>	
		      	    </Content>
		      	  </Layout>
		    </Layout>
	)
}

export default Main;