import { observable, toJS } from "mobx";
import _ from "lodash";
import { gql } from "@apollo/client";

class User {
	@observable data = null;
	@observable type = null;
	@observable info = null;
	@observable subscription = null;

	constructor() {
		this.setData = (data) => {
			return new Promise((resolve) => {
				this.data = data;
				setTimeout(() => {
					resolve(true);
				}, 100);
			});
		};

		this.getToken = () => {
			return this.data.token;
		};

		this.setLogin = async (data, client) => {
			return new Promise(async (resolve, reject) => {
				try {
					//
					// Set User Data
					//

					await localStorage.setItem("user", JSON.stringify(data));
					this.data = data;

					//
					// Get User Type
					//

					const type = await client
						.query({
							query: gql`
								query GetUserType {
									GetUserType {
										type
										subtype
									}
								}
							`,
							fetchPolicy: "no-cache",
						})
						.then(({ data }) => data.GetUserType);
					this.info = type;

					//
					// Resolve
					//

					resolve(true);
				} catch (e) {
					console.log(e);
					reject(e);
				}
			});
		};

		this.check = async (client) => {
			return new Promise(async (resolve, reject) => {
				try {
					let currentData = localStorage.getItem("user");
					if (!currentData) return reject(false);
					let data = JSON.parse(currentData);

					client
						.query({
							query: gql`
								query AuthCheck($token: String!) {
									AuthCheck(token: $token) {
										pending
										result
									}
								}
							`,
							fetchPolicy: "no-cache",
							variables: {
								token: data.token,
							},
						})
						.then((result) => {
							if (!result.error) {
								if (result?.data?.AuthCheck.result) {
									this.data = data;
									resolve(result.data.AuthCheck);
								} else {
									reject(false);
								}
							} else {
								reject(false);
							}
						})
						.catch((e) => {
							reject("server_error");
						});
				} catch (e) {
					reject(false);
				}
			});
		};

		this.logout = () => {
			return new Promise(async (resolve, reject) => {
				try {
					localStorage.removeItem("user");
					resolve(true);
				} catch (e) {
					reject(e);
				}
			});
		};

		this.get = (path) => {
			return _.get(toJS(this.data), path);
		};
		this.set = (path, value) => {
			return _.set(this.data, path, value);
		};
	}
}

const user = new User();
export default user;
